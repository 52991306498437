@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  
}

.container {
  max-width: 1250px;
}

.nuevo-pto{
  font-weight: bold;
  text-transform: uppercase;
}

.btn {
  font-weight: bold;
}
.form-group label,
.list-group-item {
  font-size: 1.4rem;
}

.navbar .btn {
  flex:0 1 auto;
}

@media (min-width:768px){
    .navbar .btn {
      flex: 0 1 auto !important;
    }
}
.acciones {
  margin: 1rem 0;
}
@media (min-width:768px) {
  .acciones{
    margin: 0;
  }
}
.acciones > *{
  flex: 1;
}

.signatureCanvas {
  border : 1px solid #333;
  position: absolute;
  width: 100%;
  height: 100%;
}
.signatureContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
  height: 200px;
  
}

.avatar {
  max-width: 750px;
  border: none;
  margin: 0 auto;
}

.avatar .row .col-md-4 .card-img {
  border-radius: calc(6.25rem - 1px) !important;
  max-width: 135px;
  margin-bottom: 15px;
  left: 50%;
  transform: translate(-50%);
  position: relative;
}

.card-body {
  border-left: 1px solid rgba(0,0,0,0.2);
  padding-bottom: 0;
  padding-top: 0;
}

.logo {
  max-width: 200px
}

.bg-primary{background-color:#004185 !important}

.form-info {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: justify;
}

.login-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #004185;
}

.btn-login {
  background-color: #004185;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  border-radius:10px;
  width: 100%;
  text-transform: uppercase;
}

.btn-login:hover {
  color: white;
  background-color: #034e9e;
}

/* CSS SPINNER */

.sk-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #004185;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* Menu ICON CSS */
.dropdown {
  
  display: none;
}

.menu-icon {
  max-width: 30px;
}

#sidebarMenu {
  /* height: 100%; */
  min-height: 100vh;
  z-index: 10;
  position: absolute;
  left: -250px;
  width: 250px;
  top: 100%;
  transform: translateX(-250px);
  transition: transform 250ms ease-in-out;
  background: linear-gradient(180deg, #004185 0%, #014894 100%);
}
.sidebarMenuInner{
  margin:0;
  padding:0;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li{
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.sidebarMenuInner li:hover{
  background-color: #0362c7;
  color: #fff;
  
}
.sidebarMenuInner li span.navigation-subtitle{
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.50);
}
.sidebarMenuInner li a{
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(250px);
}

input[type=checkbox] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  /* position: absolute; */
  z-index: 99;
  height: 100%;
  width: 100%;
  /* top: 22px; */
  right: 15px;
  height: 22px;
  width: 22px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}
.navigation-bar-menu {
  padding: 1.8rem 1rem;
}

table.table-to-card-historic	th { 
  /* Behave  like a "row" */
  min-width: 50px;
}

table.table-to-card-historic	th.extended { 
  /* Behave  like a "row" */
  min-width: 105px;
}
/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.

TABLE TO CARD PTO CODE 
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table.table-to-card, table.table-to-card thead,table.table-to-card tbody,table.table-to-card th,table.table-to-card td,table.table-to-card tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	table.table-to-card thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
  table.table-to-card tr { border: 1px solid #ccc; 
      margin-bottom: 15px;
    }
	
    table.table-to-card	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
  table.table-to-card	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	table.table-to-card td:nth-of-type(1):before { content: "Employee"; }
	table.table-to-card td:nth-of-type(2):before { content: "Department"; }
	table.table-to-card td:nth-of-type(3):before { content: "Status"; }
  table.table-to-card td:nth-of-type(4):before { content: "Created At"; }
  table.table-to-card td:nth-of-type(5):before { content: "Beginning"; }
  table.table-to-card td:nth-of-type(6):before { content: "End"; }
  table.table-to-card td:nth-of-type(7):before { content: "Delivery Status"; }
  table.table-to-card td:nth-of-type(8):before { content: "Absence"; }
	table.table-to-card td:nth-of-type(9):before { content: "Hours"; }
  table.table-to-card td:nth-of-type(10):before { content: "Actions"; }
  
  table.table-to-card th, table.table-to-card td {
    /* padding: 1.75rem */
    padding-left: 50%;
  }

  /* End Table To Card PTO */

  /* Force table to not be like tables anymore */
	table.table-to-card-employees, table.table-to-card-employees thead,table.table-to-card-employees tbody,table.table-to-card-employees th,table.table-to-card-employees td,table.table-to-card-employees tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	table.table-to-card-employees thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
  table.table-to-card-employees tr { border: 1px solid #ccc; 
      margin-bottom: 15px;
    }
	
    table.table-to-card-employees	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
  table.table-to-card-employees	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	table.table-to-card-employees td:nth-of-type(1):before { content: "Employee"; }
	table.table-to-card-employees td:nth-of-type(2):before { content: "Department"; }
	table.table-to-card-employees td:nth-of-type(3):before { content: "Hire Date"; }
  table.table-to-card-employees td:nth-of-type(4):before { content: "Last Increment"; }
  table.table-to-card-employees td:nth-of-type(5):before { content: "Available Hours"; }
  table.table-to-card-employees td:nth-of-type(6):before { content: "Carry Over"; }
  table.table-to-card-employees td:nth-of-type(7):before { content: "Sick Days Balance"; }
  table.table-to-card-employees td:nth-of-type(8):before { content: "Actions"; }
  
  table.table-to-card-employees th, table.table-to-card-employees td {
    /* padding-left: 1.75rem */
    padding-left: 50%;
  }

  /* End Table To Card Employees */

  /* Force table to not be like tables anymore */
	table.table-to-card-historic, table.table-to-card-historic thead,table.table-to-card-historic tbody,table.table-to-card-historic th,table.table-to-card-historic td,table.table-to-card-historic tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	table.table-to-card-historic thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
  table.table-to-card-historic tr { border: 1px solid #ccc; 
      margin-bottom: 15px;
    }
	
    table.table-to-card-historic	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
  table.table-to-card-historic	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	table.table-to-card-historic td:nth-of-type(1):before { content: "#"; }
	table.table-to-card-historic td:nth-of-type(2):before { content: "Date"; }
	table.table-to-card-historic td:nth-of-type(3):before { content: "Available Hours Before"; }
  table.table-to-card-historic td:nth-of-type(4):before { content: "Available Hours After"; }
  table.table-to-card-historic td:nth-of-type(5):before { content: "Carry Over Before"; }
  table.table-to-card-historic td:nth-of-type(6):before { content: "Carry Over After"; }
  table.table-to-card-historic td:nth-of-type(7):before { content: "Hours"; }
  table.table-to-card-historic td:nth-of-type(8):before { content: "Type"; }
  table.table-to-card-historic td:nth-of-type(9):before { content: "Increment Date Before"; }
  table.table-to-card-historic td:nth-of-type(10):before { content: "Increment Date After"; }
  table.table-to-card-historic td:nth-of-type(11):before { content: "Seniority Increment Before"; }
  table.table-to-card-historic td:nth-of-type(12):before { content: "Seniority Increment After"; }
  table.table-to-card-historic td:nth-of-type(13):before { content: "Observarions"; }
  
  table.table-to-card-historic th, table.table-to-card-historic td {
    /* padding-left: 1.75rem */
    padding-left: 50%;
  }

  .card-avatar-container {
    display: flex;
    justify-content: flex-start;
  }

  .card-title {
    text-align: left;
  }
  .card-text {
    text-align: center;
  }

  .calendar-view {
    display: none;
  }

  .charts {
    display: none;
  }

  #sidebarMenu {
    position: absolute;
    top: 100%;
  }
	
}


/* Calendar */
.fc-button-primary {
  background-color: #004185 !important;
}

.fc-button-primary:hover {
  background-color: #034fa0 !important;
}

.fc-time {
  display: none;
}

.fc-title {
  margin-left: 10px;
}

.manager-title {
  flex-flow: wrap;
}

/* DatePicker */
.react-datepicker-wrapper {
  display: block !important;
}

.title-custom {
  width: 100%;
}

.userdatepicker {
  width: 100%;
}

.carryOverAlert {
  font-size: 0.75rem;
}

.general-summary {
  font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
  }

  .react-datepicker__day--highlighted {
    background-color: crimson !important;
    color: white !important;
  }

  .totalHour-positive {
    color: #02B875;
    font-weight: 700;
  }

.home-paragraph {
    line-height: 10px;
}

.since-paragraph {
  line-height: 15px;
}

.col-actions{
  width: 35% !important;
}

th {
  min-width: 105px;
}

/* td {
  text-align: center;
} */

.acciones {
  min-width: 288px;
}

.card-pallete {
  border-left: none;
}

.pallete-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  flex-wrap: wrap;
}

.color-indicator {
  height: 10px;
  width: 10px;
  background-color: #004185;
  /* margin-right: 1rem; */
  padding: 3px;
  margin-right: 5px;
  border-radius: 50%;
}

.pallette-departments{
  display: flex;
  align-items: center;
  font-size: .5rem;
}

@media(min-width: 768px) {
  .pallete-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .color-indicator {
    height: 15px;
    width: 15px;
    background-color: #004185;
    /* margin-right: 1rem; */
    padding: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }

  .pallette-departments{
    font-size: 1rem;
  }
}

.fc-today-button {
  display:none;
}

/* Footer */
footer {
  background: #393939;
  background-image: linear-gradient(135deg,#303030,#242424);
  padding: 20px;
  height: 200px;
    text-align: center;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mega-menu {
  position: relative;
}

.mega-menu-content {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  background: #004185;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mega-menu:hover .mega-menu-content {
  display: block;
}

.mega-menu-content a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.mega-menu-content a:hover {
  background: #1559cf;
}

.form-title {
  padding: 5px 15px;
  color:white;
  background-color: #004185;
  font-size: 1.5rem;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #004185;
  color: white;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  right: -40px;
  background-color: #004185;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.sidebar-content {
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.table {
  -webkit-box-shadow: 0px 0px 22px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 22px -6px rgba(0,0,0,0.75);
box-shadow: 0px 0px 22px -6px rgba(0,0,0,0.75);
}

.navbar{
  position: sticky;
  top: 0;
  z-index: 9;
}

.card-avatar {
  -webkit-box-shadow: 0px 0px 22px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 22px -6px rgba(0,0,0,0.75);
box-shadow: 0px 0px 22px -6px rgba(0,0,0,0.75);
padding: 15px;
}